// @jsx jsx

import { jsx } from 'theme-ui';
import React from 'react';
import { Flex, Box, Text, Heading } from 'rebass';
import { graphql } from 'gatsby';
import Image, { FluidObject } from 'gatsby-image';
import Marked from 'marked';

import MainContainer from '../container/container';
import { LinkButtonProps } from '../link-button/link-button';

interface SideBySideSectionProps {
  data?: {
    textOn?: 'left' | 'right';
    backgroundColor?: 'text' | 'primary' | 'secondary' | 'background';
    color?: 'text' | 'primary' | 'secondary' | 'background';
    header?: string;
    paragraph?: {
      paragraph?: string;
    };
    links?: LinkButtonProps[];
    image?: {
      title?: string;
      description?: string;
      fluid?: FluidObject;
    };
    videoLink?: string;
  };
}

export const sideBySideSectionQuery = graphql`
  fragment SideBySideSection on ContentfulSideBySideSection {
    id
    header
    image {
      id
      title
      description
      fluid(maxWidth: 1000) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    paragraph {
      paragraph
    }
    links {
      ...Links
    }
    videoLink
    backgroundColor
    color
    textOn
  }
`;

const SideBySideSection = ({
  data: {
    header,
    image,
    paragraph,
    links,
    videoLink,
    backgroundColor,
    color,
    textOn,
    ...rest
  }
}: SideBySideSectionProps) => {
  return (
    <Box py={5} as="section" bg={backgroundColor} color={color}>
      <MainContainer>
        <Flex
          flexDirection={textOn === 'left' ? 'row' : 'row-reverse'}
          flexWrap="wrap"
          alignItems="center"
          width="100%"
        >
          <Box
            width={['100%', '100%', '50%']}
            {...(textOn === 'left'
              ? { pr: [0, 0, `${(1 / 12) * 100}%`] }
              : { pl: [0, 0, `${(1 / 12) * 100}%`] })}
          >
            <Heading>{header}</Heading>
            {paragraph && paragraph.paragraph && (
              <Text
                maxWidth="720px"
                variant=""
                sx={{ hyphens: 'auto' }}
                dangerouslySetInnerHTML={{
                  __html:
                    paragraph && Marked(paragraph.paragraph, { breaks: true })
                }}
              />
            )}{' '}
          </Box>
          <Box width={['100%', '100%', `${(6 / 12) * 100}%`]}>
            <Image fluid={image.fluid} alt={image.description} />
          </Box>
        </Flex>
      </MainContainer>
    </Box>
  );
};

export default SideBySideSection;
