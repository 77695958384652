import React from 'react';
import { graphql, PageProps } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo/seo';
import { ISiteMetadata } from '../hooks/useSiteMetadata';
import ComponentMatch from '../components/component-match/component-match';
import { ICardDataQuery } from '../components/gallery-section/gallery-section';

interface PageQueryProps extends ISiteMetadata {
  contentfulPage: {
    id: string;
    slug: string;
    title?: string;
    metaTitle?: string;
    metaDescription?: string;
    metaImage?: {
      metaImageTitle?: string;
      metaImageDescription?: string;
      metaImage?: {
        src?: string;
      };
    };
    sections: any;
    noIndexNoFollow: boolean;
    galleryWeight: number;
  };
  galleryNodes: ICardDataQuery;
}

const Page = ({
  data: {
    contentfulPage: {
      id,
      slug,
      title,
      metaTitle,
      metaDescription,
      noIndexNoFollow,
      metaImage,
      sections
    },
    galleryNodes: { edges: galleryNodes }
  },
  pageContext,
  path
}: PageProps<PageQueryProps>) => {
  return (
    <Layout>
      <SEO
        title={metaTitle}
        description={metaDescription}
        image={metaImage && metaImage.metaImage && metaImage.metaImage.src}
        pathname={path}
        lang={'en'}
        noIndexNoFollow={noIndexNoFollow}
      />
      <main>
        {sections.map(section => (
          <ComponentMatch
            data={section}
            galleryNodes={galleryNodes}
            key={section.id}
          />
        ))}
      </main>
    </Layout>
  );
};

export const pageQuery = graphql`
  query PageQuery($slug: String!, $galleryCategories: [String]!) {
    site {
      siteMetadata {
        title
        description
      }
    }
    contentfulPage(slug: { eq: $slug }) {
      id
      slug
      title
      metaTitle
      metaDescription
      metaImage {
        metaImageTitle: title
        metaImageDescription: description
        metaImage: fixed(width: 1200) {
          ...GatsbyContentfulFixed_noBase64
        }
      }
      noIndexNoFollow
      sections {
        __typename
        ... on Node {
          id
        }
        ...ContactSection
        ...GallerySection
        ...HeroSection
        ...LogoSection
        ...MapSection
        ...SideBySideSection
        ...StatsSection
        ...TextSection
        ...WizeCareersSection
      }
    }
    galleryNodes: allContentfulPage(
      filter: {
        isDummyPage: { ne: true }
        galleryCategories: { elemMatch: { name: { in: $galleryCategories } } }
      }
      sort: { fields: [galleryWeight], order: ASC }
    ) {
      edges {
        node {
          id
          isDummyPage
          slug
          metaTitle
          metaDescription
          metaImage {
            metaImageTitle: title
            metaImageDescription: description
            metaImageFluid: fluid(maxWidth: 1400) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          galleryCategories {
            name
          }
        }
      }
    }
  }
`;

export default Page;
