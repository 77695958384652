// @jsx jsx

import { jsx } from 'theme-ui';
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Marked from 'marked';
import { Box, Text } from 'rebass';

import MainContainer from '../container/container';

interface TextSectionProps {
  data: {
    backgroundColor?: 'text' | 'primary' | 'secondary' | 'background';
    color?: 'text' | 'primary' | 'secondary' | 'background';
    body?: {
      body?: string;
    };
  };
}

export const textSectionQuery = graphql`
  fragment TextSection on ContentfulTextSection {
    id
    backgroundColor
    color
    body {
      body
    }
  }
`;

const TextSection = ({
  data: { backgroundColor, color, body, ...rest }
}: TextSectionProps) => {
  return (
    <Box my={5} as="section" bg={backgroundColor} color={color}>
      <MainContainer>
        <Text
          variant="renderedMarkdown"
          dangerouslySetInnerHTML={{
            __html: Marked(body && body.body, { breaks: true })
          }}
          sx={{ color, backgroundColor }}
          className="text-section"
        />
      </MainContainer>
    </Box>
  );
};

export default TextSection;
