// @jsx jsx

import { jsx } from 'theme-ui';
import React from 'react';
import {
  Flex,
  Button,
  Box,
  Link,
  Text,
  Heading,
  CardProps,
  ImageProps
} from 'rebass';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';

import MainContainer from '../container/container';
import { LinkButtonProps } from '../link-button/link-button';

interface MapSectionProps {
  header?: string;
  paragraph?: {
    paragraph?: string;
  };
  backgroundColor?: 'text' | 'primary' | 'secondary' | 'background';
  color?: 'text' | 'primary' | 'secondary' | 'background';
  links?: LinkButtonProps[];
  mapLink?: {
    mapLink?: string;
  };
}

export const mapSectionQuery = graphql`
  fragment MapSection on ContentfulMapSection {
    id
    header
    paragraph {
      paragraph
    }
    links {
      ...Links
    }
    color
    backgroundColor
    mapLink {
      mapLink
    }
  }
`;

const MapSection = ({
  header,
  paragraph,
  links,
  color,
  backgroundColor,
  mapLink,
  ...rest
}: MapSectionProps) => {
  return (
    <Box py={5} as="section">
      <MainContainer>Hello World</MainContainer>
    </Box>
  );
};

export default MapSection;
