// @jsx jsx

import { jsx } from 'theme-ui';
import React from 'react';
import { Flex, Box, Heading } from 'rebass';
import { graphql } from 'gatsby';
import Image, { FluidObject } from 'gatsby-image';

import MainContainer from '../container/container';
import LinkButton, { LinkButtonProps } from '../link-button/link-button';

interface LogoSectionProps {
  data: {
    header?: string;
    subheader?: string;
    logos?: ContentfulFluidImage[];
    logosPerRow?: 2 | 3 | 4 | 5 | 6;
    links?: LinkButtonProps[];
    backgroundColor?: 'text' | 'primary' | 'secondary' | 'background';
    color?: 'text' | 'primary' | 'secondary' | 'background';
  };
}

interface ContentfulFluidImage {
  id?: string;
  title?: string;
  description?: string;
  fluid?: FluidObject;
}

export const logoSectionQuery = graphql`
  fragment LogoSection on ContentfulLogoSection {
    id
    backgroundColor
    color
    header
    subheader
    logos {
      id
      title
      description
      fluid(maxWidth: 500) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    logosPerRow
    links {
      ...Links
    }
  }
`;

const LogoSection = ({
  data: {
    backgroundColor,
    color,
    header,
    subheader,
    logos,
    logosPerRow,
    links,
    ...rest
  }
}: LogoSectionProps) => {
  const gridSpacer = 1;
  const gridSpacerUnit = 'rem';
  const columnsToWidth = (
    numberOfColumns: LogoSectionProps['data']['logosPerRow']
  ) => {
    // ToDo: Refactor this grid creator into own component. Found here and in gallery-section.
    const lookup = {
      2: `calc(${100 / 2}% - ${gridSpacer * 2 + gridSpacerUnit})`,
      3: `calc(${100 / 3}% - ${gridSpacer * 2 + gridSpacerUnit})`,
      4: `calc(${100 / 4}% - ${gridSpacer * 2 + gridSpacerUnit})`,
      5: `calc(${100 / 5}% - ${gridSpacer * 2 + gridSpacerUnit})`,
      6: `calc(${100 / 6}% - ${gridSpacer * 2 + gridSpacerUnit})`
    };
    return lookup[numberOfColumns];
  };

  return (
    <Box
      py={5}
      as="section"
      bg={backgroundColor}
      color={color}
      textAlign="center"
    >
      <MainContainer>
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width="100%"
        >
          {header && <Heading variant="heading">{header}</Heading>}
          {subheader && (
            <Heading as="h3" variant="subheading">
              {subheader}
            </Heading>
          )}
        </Flex>
        {logos && (
          <Flex
            width={`calc(100% + ${gridSpacer * 2 + gridSpacerUnit})`}
            flexWrap="wrap"
            alignItems="center"
            mx="-1.5rem"
            mt={4}
          >
            {logos.map(({ id, fluid }) => (
              <Box
                key={id}
                width={[
                  columnsToWidth(2),
                  columnsToWidth(2),
                  columnsToWidth(logosPerRow)
                ]}
                m={gridSpacer + gridSpacerUnit}
              >
                <Box maxWidth="160px" mx="auto">
                  <Image fluid={fluid} alt="Customer logo" />
                </Box>
              </Box>
            ))}
          </Flex>
        )}
        {links && (
          <Flex my={[2, 3]}>
            {links.map(link => (
              <LinkButton key={link.id} {...link} mx="2" />
            ))}
          </Flex>
        )}
      </MainContainer>
    </Box>
  );
};

export default LogoSection;
