// @jsx jsx

import { jsx } from 'theme-ui';
import * as CSS from 'csstype';
import React from 'react';
import { Flex, Button, Box, Link, Text, Heading } from 'rebass';
import { graphql } from 'gatsby';
import Image, { FluidObject } from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';
import ReactModal from 'react-modal';
import { useModal } from 'react-modal-hook';
import ReactPlayer from 'react-player/lazy';
import GrPlayFill from '@meronex/icons/gr/GrPlayFill';

import MainContainer from '../container/container';
import LinkButton, { LinkButtonProps } from '../link-button/link-button';
import {
  TextAlignProps,
  VerticalAlignProps,
  JustifyContentProps,
  AlignItemsProps
} from 'styled-system';

interface HeroSectionProps {
  data: {
    header?: string;
    subheader?: string; // Make header h1 style if subheader is set
    paragraph?: {
      paragraph?: string;
    };
    image?: { fluid: FluidObject; title: string; description: string };
    videoUrl?: string;
    imageBlur?: number;
    imageBrightness?: number;
    textAligned?: CSS.Property.TextAlign;
    verticalPlacementOfText?: 'top' | 'middle' | 'bottom';
    imagePosition?: 'background' | 'left' | 'right';
    backgroundColor?: 'text' | 'primary' | 'secondary' | 'background';
    color?: 'text' | 'primary' | 'secondary' | 'background';
    links?: LinkButtonProps[];
  };
}

export const HeroSectionQuery = graphql`
  fragment HeroSection on ContentfulHero {
    id
    header
    subheader
    paragraph {
      paragraph
    }
    image {
      title
      description
      fluid(maxWidth: 3500) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    imageBlur
    imageBrightness
    videoUrl
    textAligned
    verticalPlacementOfText
    imagePosition
    backgroundColor
    color
    links {
      ...Links
    }
  }
`;

const HeroSection = ({
  data: {
    header, // h1 style if subheader is present, otherwise h2
    subheader,
    paragraph,
    image,
    imageBlur,
    imageBrightness,
    videoUrl,
    textAligned,
    verticalPlacementOfText,
    imagePosition,
    backgroundColor,
    color,
    links,
    ...rest
  }
}: HeroSectionProps) => {
  const [showVideoModal, hideVideoModal] = useModal(() => (
    <ReactModal
      isOpen
      onRequestClose={hideVideoModal}
      appElement={document.getElementById('___gatsby')}
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(255, 255, 255, 0.75)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        },
        content: {
          position: 'relative',
          top: 'unset',
          left: 'unset',
          right: 'unset',
          bottom: 'unset',
          border: '1px solid #ccc',
          background: '#fff',
          overflow: 'auto',
          WebkitOverflowScrolling: 'touch',
          borderRadius: '4px',
          outline: 'none',
          padding: '0',
          width: '100%',
          maxWidth: '80vw'
        }
      }}
    >
      <Box className="player-wrapper" width="100%">
        <ReactPlayer
          url={videoUrl}
          width="100%"
          height="100%"
          className="react-player"
          config={{
            youtube: {
              playerVars: { controls: 1, modestBranding: 1 }
            }
          }}
        />
      </Box>
    </ReactModal>
  ));

  const verticalPlacementOfTextToAlignItems = (
    verticalPlacement: HeroSectionProps['data']['verticalPlacementOfText']
  ) => {
    const lookup = { top: 'flex-start', middle: 'center', bottom: 'flex-end' };
    return lookup[verticalPlacement] as CSS.Property.AlignItems;
  };

  const textAlignedToJustifyContent = (
    textAlign: HeroSectionProps['data']['textAligned']
  ) => {
    const lookup = { left: 'flex-start', middle: 'center', right: 'flex-end' };
    return lookup[textAlign] as CSS.Property.JustifyContent;
  };

  const HeroBackground = ({ imagePosition, fluid, children, ...rest }) => {
    const BackgroundComponent =
      imagePosition === 'background' && fluid ? BackgroundImage : Box;

    return (
      <BackgroundComponent {...(fluid && { fluid })} {...rest}>
        {children}
      </BackgroundComponent>
    );
  };

  const shouldRemoveStroke = (image, imagePosition) => {
    return !image || (image && imagePosition !== 'background');
  };

  const noStroke = {
    sx: {
      textShadow: 'unset',
      WebkitTextStrokeWidth: 0,
      WebkitTextStrokeColor: 0
    }
  };

  const backgroundSx = {
    minHeight: '450px',
    display: 'flex',
    backgroundColor: backgroundColor,
    py: 4,
    '&::after, &::before': {
      content: '""',
      filter: `blur(${imageBlur}px) brightness(${imageBrightness})`
    }
  };

  const leftImagePadding = {
    pl: [0, 0, `${(1 / 12) * 100}%`]
  };

  const rightImagePadding = {
    pr: [0, 0, `${(1 / 12) * 100}%`]
  };

  const centerButtons = {
    sx: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }
  };

  const halfWidth = {
    width: ['100%', '100%', `${(6 / 12) * 100}%`]
  };

  return (
    <Box
      as="section"
      color={color}
      {...(!image ||
        (imagePosition !== 'background' && {
          backgroundColor: backgroundColor
        }))}
    >
      <HeroBackground
        imagePosition={imagePosition}
        fluid={image && image.fluid}
        sx={backgroundSx}
      >
        <MainContainer py={3}>
          <Flex
            width={'100%'}
            flexDirection={textAligned === 'left' ? 'row' : 'row-reverse'}
            flexWrap="wrap"
            justifyContent={textAlignedToJustifyContent(textAligned)}
            alignItems={verticalPlacementOfTextToAlignItems(
              verticalPlacementOfText
            )}
            textAlign={textAligned === 'center' ? 'center' : 'left'}
          >
            <Box
              {...(textAligned !== 'center' && halfWidth)}
              {...(textAligned === 'right'
                ? leftImagePadding
                : rightImagePadding)}
            >
              {header && (
                <Heading
                  as="h1"
                  width="100%"
                  variant="heroHeading"
                  {...(shouldRemoveStroke(image, imagePosition) && noStroke)}
                >
                  {header}
                </Heading>
              )}
              {subheader && (
                <Heading
                  as="h2"
                  variant="heroSubheading"
                  {...(shouldRemoveStroke(image, imagePosition) && noStroke)}
                >
                  {subheader}
                </Heading>
              )}
              {paragraph?.paragraph && (
                <Text
                  maxWidth="600px"
                  mb={4}
                  variant="heroParagraph"
                  {...(shouldRemoveStroke(image, imagePosition) && noStroke)}
                >
                  {paragraph?.paragraph}
                </Text>
              )}
              {videoUrl && imagePosition === 'background' && (
                <Flex {...(textAligned === 'center' && centerButtons)}>
                  <LinkButton
                    type="button"
                    my={[2, 4]}
                    sx={{
                      borderRadius: '50%',
                      backgroundColor: 'background',
                      padding: 0,
                      border: 0,
                      height: '4rem',
                      width: '4rem',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                    variant="primary"
                    onClick={showVideoModal}
                  >
                    <GrPlayFill
                      size="2rem"
                      style={{
                        position: 'relative',
                        left: '.2rem'
                      }}
                    />
                  </LinkButton>
                </Flex>
              )}
              {links && links.length > 0 && (
                <Flex
                  my={[4, 4]}
                  {...(textAligned === 'center' && centerButtons)}
                >
                  {links.map((link, i) => (
                    <LinkButton
                      key={link.id}
                      {...link}
                      {...(links.length > 1 &&
                        links.length !== i + 1 && { mr: 4 })}
                    />
                  ))}
                </Flex>
              )}
            </Box>
            {imagePosition !== 'background' && (
              <Box
                width={['100%', '100%', `${(6 / 12) * 100}%`]}
                sx={{ position: 'relative', display: 'inline-block' }}
              >
                <Image fluid={image.fluid} alt={image.description} />
                <div
                  sx={{
                    position: 'absolute',
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%, -50%)'
                  }}
                >
                  {videoUrl && (
                    <LinkButton
                      type="button"
                      my={[2, 4]}
                      sx={{
                        borderRadius: '50%',
                        backgroundColor: 'background',
                        padding: 0,
                        border: 0,
                        height: '4rem',
                        width: '4rem',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                      variant="primary"
                      onClick={showVideoModal}
                    >
                      <GrPlayFill
                        size="2rem"
                        style={{
                          position: 'relative',
                          left: '.2rem'
                        }}
                      />
                    </LinkButton>
                  )}
                </div>
              </Box>
            )}
          </Flex>
        </MainContainer>
      </HeroBackground>
    </Box>
  );
};

export default HeroSection;
