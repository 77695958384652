// @jsx jsx

import { jsx } from 'theme-ui';
import React from 'react';
import { Flex, Box, Text, Heading } from 'rebass';
import { graphql } from 'gatsby';
import CountUp from 'react-countup';
import LazyLoad from 'react-lazyload';
import Marked from 'marked';

import MainContainer from '../container/container';
import LinkButton, { LinkButtonProps } from '../link-button/link-button';

interface StatsSectionProps {
  data: {
    id: string;
    header?: string;
    subheader?: string;
    paragraph?: {
      paragraph?: string; // Look into refactoring markdown properties so that markdown conversion happens in graphql
    };
    stats?: IStat[];
    columns?: 1 | 2 | 3 | 4;
    links?: LinkButtonProps[];
    backgroundColor?: 'text' | 'primary' | 'secondary' | 'background'; // ToDo: refactor color options into one place
    color?: 'text' | 'primary' | 'secondary' | 'background';
    numberColor?: 'text' | 'primary' | 'secondary' | 'background';
  };
}

interface IStat {
  id: string;
  numberPrefix?: string;
  numberSuffix?: string;
  number: number;
  text: {
    text: string;
  };
  decimalPlaces?: number;
  animationDuration?: number;
}

export const statsSectionQuery = graphql`
  fragment StatsSection on ContentfulStatsSection {
    id
    header
    subheader
    paragraph {
      paragraph
    }
    stats {
      id
      numberPrefix
      numberSuffix
      number
      text {
        id
        text
      }
      decimalPlaces
      animationDuration
    }
    columns
    links {
      ...Links
    }
    backgroundColor
    color
    numberColor
  }
`;

const StatsSection = ({
  data: {
    id,
    header,
    subheader,
    paragraph,
    stats,
    columns,
    links,
    backgroundColor,
    color,
    numberColor,
    ...rest
  }
}: StatsSectionProps) => {
  const gridSpacer = 1;
  const gridSpacerUnit = 'rem';
  const columnsToWidth = (
    numberOfColumns: StatsSectionProps['data']['columns']
  ) => {
    // ToDo: Refactor this grid creator into own component. Found here and in gallery-section.
    const lookup = {
      2: `calc(${100 / 2}% - ${gridSpacer * 2 + gridSpacerUnit})`,
      3: `calc(${100 / 3}% - ${gridSpacer * 2 + gridSpacerUnit})`,
      4: `calc(${100 / 4}% - ${gridSpacer * 2 + gridSpacerUnit})`,
      5: `calc(${100 / 5}% - ${gridSpacer * 2 + gridSpacerUnit})`,
      6: `calc(${100 / 6}% - ${gridSpacer * 2 + gridSpacerUnit})`
    };
    return lookup[numberOfColumns];
  };

  return (
    <Box
      py={5}
      as="section"
      bg={backgroundColor}
      color={color}
      textAlign="left"
    >
      <MainContainer>
        <Flex
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="flex-start"
          width="100%"
        >
          {header && <Heading variant="heading">{header}</Heading>}
          {subheader && (
            <Heading as="h3" variant="subheading">
              {subheader}
            </Heading>
          )}
          {paragraph && paragraph.paragraph && (
            <Text
              maxWidth="650px"
              variant="shadedParagraph"
              sx={{ hyphens: 'auto' }}
              dangerouslySetInnerHTML={{
                __html:
                  paragraph && Marked(paragraph.paragraph, { breaks: true })
              }}
            />
          )}
        </Flex>
        {stats && (
          <Flex
            width={`calc(100% + ${gridSpacer * 2 + gridSpacerUnit})`}
            flexWrap="wrap"
            alignItems="flex-start"
            mx="-1.5rem"
            mt={4}
          >
            {stats.map(
              ({
                id,
                number,
                text,
                animationDuration,
                decimalPlaces,
                numberPrefix,
                numberSuffix
              }) => (
                <Box
                  key={id}
                  width={[
                    columnsToWidth(2),
                    columnsToWidth(2),
                    columnsToWidth(columns)
                  ]}
                  m={gridSpacer + gridSpacerUnit}
                >
                  <Box mx="auto" sx={{ textAlign: 'center' }}>
                    <LazyLoad once>
                      <Heading variant="giant">
                        <CountUp
                          end={number}
                          duration={animationDuration}
                          decimals={decimalPlaces}
                          prefix={numberPrefix}
                          suffix={numberSuffix}
                          separator=","
                        />
                      </Heading>
                    </LazyLoad>
                    <Text variant="label">{text && text.text}</Text>
                  </Box>
                </Box>
              )
            )}
          </Flex>
        )}
        {links && (
          <Flex my={[2, 3]}>
            {links.map(link => (
              <LinkButton key={link.id} {...link} mx="2" />
            ))}
          </Flex>
        )}
      </MainContainer>
    </Box>
  );
};

export default StatsSection;
