import React from 'react';

import {
  ContactSection,
  GallerySection,
  HeroSection,
  LogoSection,
  MapSection,
  SideBySideSection,
  StatsSection,
  TextSection,
  WizeCareersSection
} from '../sections';

const ComponentMatch = ({ data, galleryNodes }) => {
  const components = {
    ContentfulContactSection: ContactSection,
    ContentfulGallerySection: GallerySection,
    ContentfulHero: HeroSection,
    ContentfulLogoSection: LogoSection,
    ContentfulMapSection: MapSection,
    ContentfulSideBySideSection: SideBySideSection,
    ContentfulStatsSection: StatsSection,
    ContentfulTextSection: TextSection,
    ContentfulWizeCareersSection: WizeCareersSection
  };
  const MatchingComponent = components[data.__typename];

  return (
    <MatchingComponent
      data={data}
      {...(data.__typename === 'ContentfulGallerySection' && {
        galleryNodes: galleryNodes
      })}
    />
  );
};

export default ComponentMatch;
