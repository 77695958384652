// @jsx jsx

import { jsx } from 'theme-ui';
import React, { useEffect, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Box, Text } from 'rebass';

import './wize-careers-section.scss';
import MainContainer from '../container/container';

interface WizeCareersSectionProps {
  data: {
    id: string;
  };
}

export const WizeCareersSectionQuery = graphql`
  fragment WizeCareersSection on ContentfulWizeCareersSection {
    id
  }
`;

const WizeCareersSection = ({ data: { ...rest } }: WizeCareersSectionProps) => {
  const [jobs, setJobs] = useState([]);
  useEffect(() => {
    // get jobs, setJobs as array of job objects
    loadJobs();
  }, []);
  const loadJobs = async () => {
    const response = await fetch(
      'https://wizesolutions.bamboohr.com/jobs/embed2.php'
    );
    const htmlString = await response.text();

    setJobs(htmlString);
  };

  return (
    <Box mt={-4} mb={5} as="section">
      <MainContainer className="wize-careers-section">
        <Text
          variant="renderedMarkdown"
          dangerouslySetInnerHTML={{ __html: jobs }}
        ></Text>
      </MainContainer>
    </Box>
  );
};

export default WizeCareersSection;
