// @jsx jsx

import { jsx } from 'theme-ui';
import React, { useState, useEffect } from 'react';
import { Flex, Box, Text, Heading, Card } from 'rebass';
//change name Card to RebassCard because we want to name component Card
import { graphql } from 'gatsby';
import Image, { FluidObject } from 'gatsby-image';
import Marked from 'marked';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import MainContainer from '../container/container';
import LinkButton, { LinkButtonProps } from '../link-button/link-button';
import {
  ContentBlockProps,
  contentBlockQuery
} from '../content-block/content-block';

export interface ICardDataQuery {
  edges?: Node[];
}

interface Node {
  node?: GalleryNode;
}

interface GalleryNode {
  id?: string;
  slug?: string;
  metaTitle?: string;
  metaDescription?: string;
  metaImage: {
    metaImageTitle?: string;
    metaImageDescription?: string;
    metaImageFluid?: FluidObject;
  };
  galleryCategories: {
    name?: string;
  };
}

interface GallerySectionProps {
  data: {
    header?: string;
    paragraph?: {
      paragraph?: string;
    };
    cardAppearance?:
      | 'Card'
      | 'Raw'
      | 'Overlay Text on Image'
      | 'Open Image in Lightbox';
    cardColumns?: 2 | 3 | 4;
    categories?: string;
    links?: LinkButtonProps[];
    contentBlock?: ContentBlockProps[];
  };
  galleryNodes?: Node[];
}

export const galleryQuery = graphql`
  fragment GallerySection on ContentfulGallerySection {
    id
    header
    paragraph {
      paragraph
    }
    cardColumns
    cardAppearance
    categories {
      name
    }
    links {
      ...Links
    }
    contentBlock: contentBlocksForGallery {
      ...ContentBlock
    }
  }
`;

const GallerySection = ({
  data: {
    header,
    paragraph,
    cardColumns,
    cardAppearance,
    categories,
    links,
    contentBlock,
    ...rest
  },
  galleryNodes
}: GallerySectionProps) => {
  const lightbox = cardAppearance === 'Open Image in Lightbox' ? true : false;
  const formattedData = [];
  const formatCardData = (contentBlock = null, galleryNodes = null) => {
    contentBlock?.map(
      ({
        id,
        blockHeader,
        blockSubheader,
        blockParagraph,
        blockImages,
        link
      }) => {
        formattedData.push({
          id,
          header: blockHeader,
          subheader: blockSubheader,
          description: blockParagraph?.blockParagraph,
          images: blockImages?.map(({ fluid }) => fluid),
          imageDescription: blockImages?.description,
          slug: link?.link
        });
      }
    );

    galleryNodes?.map(
      ({ node: { id, metaTitle, metaDescription, metaImage, slug } }) => {
        formattedData.push({
          id,
          header: metaTitle,
          subheader: '',
          description: metaDescription,
          images: [metaImage?.metaImageFluid],
          imageDescription: metaImage?.metaImageDescription,
          slug: slug
        });
      }
    );

    return formattedData;
  };
  const [standardizedCardData, setStandardizedCardData] = useState([]);
  useEffect(() => {
    setStandardizedCardData(formatCardData(contentBlock, galleryNodes));
  }, []);
  const cardMargin = 1;
  const cardMarginUnit = 'rem';
  const columnsToWidth = (
    numberOfColumns: GallerySectionProps['data']['cardColumns']
  ) => {
    const lookup = {
      2: `calc(50% - ${cardMargin * 2 + cardMarginUnit})`,
      3: `calc(33.33333333% - ${cardMargin * 2 + cardMarginUnit})`,
      4: `calc(25% - ${cardMargin * 2 + cardMarginUnit})`
    };
    return lookup[numberOfColumns];
  };
  const [photoIndex, setPhotoIndex] = useState(0);
  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);

  const openLightbox = index => {
    setPhotoIndex(index);
    setLightboxIsOpen(true);
  };

  return (
    <Box my={5} as="section">
      <MainContainer>
        {header && (
          <Heading width="100%" variant="heading">
            {header}
          </Heading>
        )}
        {paragraph && paragraph.paragraph && (
          <Text
            maxWidth="720px"
            variant="shadedParagraph"
            sx={{ hyphens: 'auto' }}
            dangerouslySetInnerHTML={{
              __html: paragraph && Marked(paragraph.paragraph, { breaks: true })
            }}
          />
        )}
        {links && links.length > 0 && (
          <Flex my={[2, 3]}>
            {links.map(link => (
              <LinkButton key={link.id} {...link} mx="2" />
            ))}
          </Flex>
        )}
        {standardizedCardData && (
          <Flex
            flexWrap="wrap"
            width={`calc(100% + ${cardMargin * 2 + cardMarginUnit})`}
            mx={`-${cardMargin + cardMarginUnit}`}
            mt={[2, 3]}
          >
            {standardizedCardData.map(
              (
                {
                  id,
                  header,
                  subheader,
                  description,
                  images,
                  imageDescription,
                  slug
                },
                cardDataIndex
              ) => (
                <Card
                  key={id}
                  {...(images?.length > 0 &&
                    !header &&
                    !subheader &&
                    !description &&
                    !slug &&
                    !lightbox && {
                      as: 'a',
                      href: images[0]?.src,
                      rel: 'noopener',
                      target: '_blank',
                      className: 'hoverable'
                    })}
                  {...(images?.length > 0 &&
                    lightbox && { className: 'hoverable' })}
                  variant="card"
                  m={cardMargin + cardMarginUnit}
                  width={[
                    '100%',
                    columnsToWidth(2),
                    columnsToWidth(cardColumns)
                  ]}
                  object-fit="cover"
                  {...(slug && { as: 'a', href: slug, className: 'hoverable' })}
                >
                  {images && !lightbox && (
                    <Image fluid={images[0]} alt={imageDescription} />
                  )}
                  {images && lightbox && (
                    <div>
                      <div onClick={() => openLightbox(cardDataIndex)}>
                        <Image fluid={images[0]} alt={imageDescription} />
                      </div>
                      {lightboxIsOpen === true && (
                        <Lightbox
                          mainSrc={
                            standardizedCardData[photoIndex]?.images[0].src
                          }
                          nextSrc={
                            standardizedCardData[photoIndex + 1]?.images[0]?.src
                          }
                          prevSrc={
                            standardizedCardData[photoIndex - 1]?.images[0]?.src
                          }
                          onCloseRequest={() => setLightboxIsOpen(false)}
                          onMovePrevRequest={() =>
                            setPhotoIndex(photoIndex - 1)
                          }
                          onMoveNextRequest={() =>
                            setPhotoIndex(photoIndex + 1)
                          }
                        />
                      )}
                    </div>
                  )}
                  {(header || subheader || description) && (
                    <Box py={[4]} px={[4]}>
                      {header && (
                        <Heading variant="cardHeading">{header}</Heading>
                      )}
                      {subheader && (
                        <Heading variant="label">{subheader}</Heading>
                      )}
                      {description && (
                        <Text
                          sx={{ hyphens: 'auto' }}
                          dangerouslySetInnerHTML={{
                            __html: Marked(description, { breaks: true })
                          }}
                        ></Text>
                      )}
                    </Box>
                  )}
                </Card>
              )
            )}
          </Flex>
        )}
        {(!standardizedCardData || standardizedCardData?.length === 0) && (
          <Text>No content has been added for this category yet</Text>
        )}
      </MainContainer>
    </Box>
  );
};

export default GallerySection;
